<template>
  <div>
    <CheckoutBillingAddressForm
      :address="formValues"
      :api-errors="apiErrors"
      :countries="countries"
      :submitting="loaderStore.isEnabled"
      :with-user-information="userInformationStore.isFormRequired"
      @submit="handleSubmit"
    />

    <ReassuranceItems class="mt-24 md:mt-56">
      <BouyguesReassuranceItems
        v-if="cartStore.bouyguesMobilePlan"
        :benefits="cartStore.bouyguesMobilePlan.benefits"
      />
    </ReassuranceItems>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from '#imports'
import { computed, onMounted, ref } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { insertIf } from '@backmarket/utils/collection/insertIf'
import { camelizeKeys } from '@backmarket/utils/object/camelizeKeys'

import ReassuranceItems from '~/scopes/reassurance/components/ReassuranceItems/ReassuranceItems.vue'

import BouyguesReassuranceItems from '../../components/BouyguesReassuranceItems/BouyguesReassuranceItems.vue'
import useHandleUnauthorizedUser from '../../composables/useHandleUnauthorizedUser'
import { CHECKOUT } from '../../routes-names'
import { useAddressStore } from '../../stores/addressStore'
import { useCartStore } from '../../stores/cartStore'
import { useLoaderStore } from '../../stores/loaderStore'
import { useUserInformationStore } from '../../stores/userInformationStore'
import { getBouyguesAddressAPIValidationErrors } from '../../utils/getBouyguesAddressAPIValidationErrors'

import CheckoutBillingAddressForm, {
  type FormValues,
} from './components/BillingAddressForm.vue'

const tracking = useTracking()
const router = useRouter()

const cartStore = useCartStore()
const addressStore = useAddressStore()
const userInformationStore = useUserInformationStore()
const loaderStore = useLoaderStore()
const i18n = useI18n()

const { handleUnauthorizedUser } = useHandleUnauthorizedUser()

const apiErrors = ref<{ [key: string]: string }>({})

const formValues = computed(() => {
  return {
    ...addressStore.billing,
    ...insertIf(userInformationStore.isFormRequired, {
      birthdate: userInformationStore.birthdate || '',
      nationalId: userInformationStore.nationalId,
    }),
  }
})

const countries = computed(() => {
  return [...addressStore.billableCountries]
    .map(({ name, countryCode: billableCountryCode }) => ({
      value: billableCountryCode,
      label: name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
})

onMounted(() => {
  loaderStore.disable()
  tracking.trackFunnel(cartStore.trackingData(CHECKOUT.BILLING_ADDRESS))
})

const handleApiError = (error: Record<string, unknown>) => {
  Object.assign(
    apiErrors.value,
    getBouyguesAddressAPIValidationErrors(error, i18n),
  )

  const camelizedError = camelizeKeys(error)
  // FIXME Needs to update the httpApiError to manage endpointSettings.transformResponseToCamelCase
  Object.assign(
    apiErrors.value,
    Object.keys(camelizedError).reduce(
      (acc, key) => ({
        ...acc,
        [key]:
          Array.isArray(camelizedError[key]) &&
          (camelizedError[key] as Array<string>).length > 0
            ? (camelizedError[key] as Array<string>)[0]
            : '',
      }),
      {},
    ),
  )
}

const handleSubmit = async (values: FormValues) => {
  apiErrors.value = {}
  loaderStore.enable()

  try {
    tracking.trackClick({
      name: '3_billing-new-edit_cta_continue',
      zone: 'funnel',
    })

    const address = {
      ...addressStore.billing,
      ...values,
      countryDialInCode: addressStore.shipping.countryDialInCode,
      phone: addressStore.shipping.phone,
    }

    if (cartStore.hasBouyguesMobilePlan) {
      await addressStore.saveBouyguesAddress({
        address,
        isShipping: false,
        isBilling: true,
      })
    }

    // 1. Save address
    await addressStore.saveAddress({
      address,
      formType: userInformationStore.formType,
      isShipping: false,
      isBilling: true,
    })

    // 2. Reload checkout store
    await cartStore.fetchCart()
    // 3. Move to next page
    router.push({
      name: CHECKOUT.PAYMENT,
    })
  } catch (error) {
    if ((error as Record<string, unknown>)?.status === 400)
      handleApiError(error as Record<string, unknown>)
    else
      await handleUnauthorizedUser(
        error as Record<string, unknown>,
        '[CHECKOUT] Failed to submit shipping form',
      )

    loaderStore.disable()
  }
}
</script>
